<template>
    <div class="con">
        <div class="top_box">
            <div class="top_nei_box">
                <div class="title">
                    {{detail.name}}
                </div>
                <div class="flex_box">
                    <div class="icon_box"> <i class="el-icon-school"></i> <span>工作单位</span> </div>
                    <div class="icon_box"> <i class="el-icon-menu"></i> <span>研究领域</span> </div>
                </div>
            </div>

        </div>
        <div class="one_box">
            <el-divider content-position="left">专家描述</el-divider>
            <div class="two_box">
                <!--<div class="tab"><span>专家标签</span></div>-->
                <div class="title">
                <span>
                  {{detail.direction}}
                </span>
                </div>
            </div>
            <!--<div class="two_box">-->
                <!--<div class="tab">{{detail.research.name}}</div>-->
                <!--<div class="title"><span>文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文</span></div>-->
            <!--</div>-->
            <div class="two_box">
                <div class="tab"><span>{{detail.name}}</span></div>
                <div class="title"><span>文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文案文案文案文</span></div>
            </div>  <div class="two_box">
        </div>
        </div>

    </div>
</template>

<script>
    import { dexpert_xq} from "../../common/js/api";
    export default {
        name: "scientistdtail",
        data(){
            return{
                detail:{}

            }
        },
        created(){
            this.zhaun_detail()
        },
        methods:{
            zhaun_detail(){
                dexpert_xq({
                    id:this.$route.query.ids
                }).then((res) => {
                    console.log(res.data)
                    this.detail=res.data
                }).catch((err) => {
                });
            }
        }
    }
</script>

<style scoped lang="less">
    /deep/.el-divider{
        background-color: rgba(56, 146, 235, 100);
        padding-right: -30px;
    }
    /deep/.el-divider__text{
        color: rgba(56, 146, 235, 100);
        font-size: 20px;
    }
    /deep/.el-divider__text.is-left{
      left: 0px;
    }
    .el-icon-school{
        font-size: 14px;
        color:#3892EB  ;
    }
    .el-icon-menu {
        font-size: 14px;
        color: #3892EB;
    }
   .con {
       background-color: #F8F9FA;
       width: 100%;
       height: auto;
       overflow: hidden;
       .top_box {
           width: 100%;
           height: 178px;
           line-height: 20px;
           background-color: rgba(240, 248, 255, 100);
           text-align: center;
           border: 1px solid rgba(255, 255, 255, 100);
           .flex_box {
               margin-top: 15px;
               display: flex;
               .icon_box{
                   padding-right: 30px;
               }
           }
           .title{
               margin-top: 10px;
               color: rgba(16, 16, 16, 100);
               font-size: 18px;
               text-align: left;
              line-height: 30px;
           }
           .top_nei_box{
               width: 1200px;
               margin:  60px auto;
           }
       }
       .one_box {
           margin: 15px auto;
           width: 1200px;
           height: 637px;
           background-color: #fff;
           border: 1px solid rgba(255, 255, 255, 100);
       }
   }
    .two_box{
        margin: 20px;
        .title{
            margin-bottom: 30px;
            color: rgba(0, 5, 10, 100);
            font-size: 14px;
            text-align: left;
            line-height: 30px;
            font-family: SourceHanSansSC-regular;
        }
        .tab{
            display: inline-block;
            padding: 5px 15px;
            margin-bottom: 15px;
            line-height: 38px;
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 100);
            color: rgba(56, 146, 235, 100);
            font-size: 18px;
            text-align: center;
            font-family: Microsoft Yahei;
            border: 1px solid rgba(56, 146, 235, 100);
        }
    }
</style>